import React from "react";

// plugin imports
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay } from "swiper/modules";

// style imports
import "./SliderBg.scss";
import "swiper/css/effect-fade";

// component imports

// svg imports

// component
export default function SliderBg({ directory = "landingBgs" }) {
	const data = useStaticQuery(graphql`
		query MyQuery {
			landingBgs: allFile(
				filter: { relativeDirectory: { regex: "/landingBgs/" } }
				sort: { fields: name }
			) {
				nodes {
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		}
	`);

	const images = data[directory]?.nodes;
	// console.log(images);

	return (
		<Swiper
			slidesPerView={"auto"}
			effect={"fade"}
			speed={1500}
			loop={true}
			modules={[EffectFade, Autoplay]}
			autoplay={{
				autoplay: true,
				delay: 1500,
			}}
			id="sliderBg"
			className="landing-bg"
		>
			{images.map((image, i) => (
				<SwiperSlide key={"slider" + i}>
					<GatsbyImage image={getImage(image)} alt="" objectFit="cover" />
				</SwiperSlide>
			))}
		</Swiper>
	);
}
