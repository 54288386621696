import "./index.css";

import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Link } from "gatsby-plugin-react-i18next";

import Layout from "../components/layout/Layout";
import Seo from "../components/seo";
import SliderBg from "../components/sliderBg/SliderBg";
// import LandingTimelapse from "../components/layout/LandingTimelapse";

import Logo from "../svg/begin-logo-line.svg";

export default function IndexPage() {
	const { t } = useTranslation();

	const windowGlobal = typeof window !== "undefined" && window;

	const [intro, setIntro] = React.useState(() => {
		// return true
		if (windowGlobal) {
			return windowGlobal.sessionStorage.getItem("intro") || true;
		}
	});

	React.useEffect(() => {
		if (intro === true) {
			setTimeout(() => {
				setIntro(false);
				sessionStorage.setItem("intro", false);
			}, 3000);
		}
	}, [intro]);

	return (
		<Layout hideTopLogo={intro === true}>
			<Seo title={t("Home")} />

			<div className="landingBackgrounds" id="landingBackground">
				<div className="blackOverlay landing" />
				<SliderBg />
			</div>

			<div className="landingBody">
				<SwitchTransition mode="out-in">
					<CSSTransition key={`Intro${intro}`} timeout={400} classNames="fade">
						{intro !== true ? (
							<>
								<h1 style={{ animation: "none" }} className="experienciaTitle">
									{/* <Trans i18nKey="titleLanding">
										Vuelve <i>al origen</i>
									</Trans> */}
									<Trans>¿Cómo quieres vivir la experiencia?</Trans>
								</h1>
								<div id="opcionesExperiencia">
									<Link
										to="/reserva"
										className="underlineButton opcionText big"
									>
										<Trans>Reservar</Trans>
									</Link>

									<Link
										to="/menus#sectionContent"
										className="underlineButton opcionText big"
									>
										<Trans>Ver cartas</Trans>
									</Link>

									<a
										href="https://linktr.ee/beginrestaurante"
										target="_blank"
										rel="noopener noreferrer"
										aria-label={t("Pedir a domicilio")}
										className="underlineButton opcionText big"
									>
										<Trans>Pedir a domicilio</Trans>
									</a>
								</div>
							</>
						) : (
							<>
								<Logo className="logoLanding" />
								<div className="subtitleLanding" id="subtitleLanding">
									<h2>
										<Trans>Vuelve al origen</Trans>
									</h2>
								</div>
							</>
						)}
					</CSSTransition>
				</SwitchTransition>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
